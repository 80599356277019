import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useIsAuthenticated } from '@azure/msal-react';
import { startup, startupFinished } from './actions';
import SessionRouter from './components/navigation/SessionRouter';
import PublicRouter from './components/navigation/PublicRouter';
import CustomModal from './components/common/CustomModal';
import { ImpersonateSession } from './components/common/ImpersonateSession';

import { useOneIncScript } from './hooks/useOneIncScript';
import { useB2CInfo } from './hooks';

const AppRouter = () => {
  const dispatch = useDispatch();
  useOneIncScript();

  const pathname = window.location.pathname.split('/')[1];
  const store = useSelector(({
    policies,
    session,
  }) => ({ policies, session }), shallowEqual);
  const { logoutB2C } = useB2CInfo();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    switch (pathname) {
      case 'login':
        logoutB2C();
        break;
      case 'paymentMethod':
        sessionStorage.clear();
        dispatch(startupFinished());
        break;
      default:
        dispatch(startup());
    }

    return () => null;
  }, []);

  function renderPageContent() {
    const hasSession = Boolean(isAuthenticated
      && store.session.privateToken
      && store.session.userInfo);

    if (hasSession) {
      return <SessionRouter />;
    }

    return <PublicRouter />;
  }

  return (
    <Router>
      {renderPageContent()}

      <CustomModal />

      <ImpersonateSession />
    </Router>
  );
};

export default AppRouter;
