import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAlphabeticalSortedPets, usePetPolicies } from '../../hooks';
import {
  clearPolicyNotifications,
  getMultibrandDefaultPolicy,
  getMultibrandPolicies,
  getPolicyDetail,
  loadFolderStructure,
  loadMultiModalData,
  loadPlanSummaryDetails,
  loadPoliciesOverview,
  loadPolicyDetails,
  loadReimbursementMethod,
  loadWhatWeCoverInfo,
  policyDetailCleaned,
  refreshPolicies,
  setSelectedPetId,
  validateBreedMultibrand,
} from '../../actions';

import {
  ADD_ORCA_PET,
  INBOX_DOCS as INBOX_DOCS_ROUTE,
  INSURANCE,
} from '../navigation/routes.constants';
import {
  PRODUCT_FAMILY,
  MULTIBRAND_POLICY_STATUS,
  INBOX_DOCS,
} from '../../constants';
import { MARKETING_CHANNEL_ID } from '../../theme/theme.constants';

import addIcon from '../../assets/policy/add-white.svg';
import loadingSpinnerBlue
from '../../assets/lottieFiles/loading-blue.json';
import addIconOnyx from '../../assets/policy/add-onyx.svg';

import { CoverageOverview } from './coverageOverview/CoverageOverview';
import { PetCloudBreadcrumbs } from '../myPets/common/PetCloudBreadcrumbs';
import { NoPolicyPets } from './NoPolicyPets';
import { PolicyModals } from './PolicyModals';
import { CurrentPetPolicy } from './CurrentPetPolicy';
import FigoLottie from '../common/FigoLottie';
import { PolicyHeader } from './PolicyHeader';
import { Powerups } from './powerups/Powerups';
import PolicyNotifications from './PolicyNotifications';
import { FileClaimButton } from '../claims/FileClaimButton';
import { CostcoMemberQuoteModal } from '../common/costcoMemberQuote';

import { BillingAndPayments } from './billingAndPayments';
import { Reimbursement } from './reimbursement';
import { ChangeCoverageFrame } from './changeCoverage/ChangeCoverageFrame';
import {
  ProvidersSelector,
} from '../common/providersSelector/ProvidersSelector';
import { ContactUsModal } from '../sideMenu/contactUs/ContactUsModal';
import { CONTACT_US_STEPS } from '../sideMenu/contactUs/contactUs.constants';
import { ActionButton } from '../common/petCloud/ActionButton';
import { ViewMoreButton } from './ViewMoreButton';
import './css/PolicySummary.css';
import { sleep } from '../../services/utils';

const PolicySummary = ({
  onCloseInvalidBreedModal = () => { },
  onClosePPIGroupModal = () => { },
  onGetQuoteByBrand = () => { },
  showInvalidBreedModal = false,
  showPPIGroupModal = false,
}) => {
  const store = useSelector(({
    billingPayments,
    claims,
    claimsGeneric,
    inboxAndDocs,
    myVets,
    orcaInsurance,
    personalInformation,
    policies,
    session,
  }) => ({
    billingPayments,
    claims,
    claimsGeneric,
    inboxAndDocs,
    myVets,
    orcaInsurance,
    personalInformation,
    policies,
    session,
  }), shallowEqual);
  const { t } = useTranslation('insuranceScreen');
  const dispatch = useDispatch();
  const location = useLocation();
  const { petId, policyId } = useParams();
  const history = useHistory();

  const [selectedPolicy, setSelectedPolicy] = useState({});
  const [multiModalVisible, setMultiModalvisible] = useState(false);
  const [multiModalCategoryId, setMultiModalCategoryId] = useState(0);
  const [showPrimaryVetModal, setShowPrimaryVetModal] = useState(false);
  const [openChangeCoverageFrame, setOpenChangeCoverageFrame] = useState(false);
  const [showProviderSelector, setShowProviderSelector] = useState(false);
  const [
    showCostcoMemberQuoteModal,
    setShowCostcoMemberQuoteModal,
  ] = useState(false);
  const [
    showMultiplePoliciesError,
    setShowMultiplePoliciesError,
  ] = useState(false);

  const petPolicies = usePetPolicies();
  const { allPets } = petPolicies;

  const {
    billingPayments: {
      billingDetails,
      paymentMethodData,
      policyDetails,
      reimbursementMethod,
    },
    inboxAndDocs: { folderStructure },
    personalInformation,
    personalInformation: {
      userInfo: {
        Companies: companies,
      },
    },
    policies: {
      changeCoverageValidation,
      defaultPolicy,
      isYoungPetModalVisible,
      loading,
      loadingDefaultPolicy,
      loadingOtherPolicy,
      loadingOverview,
      loadingSeeMoreInfo,
      multibrandPolicies,
      multiModalData,
      otherPolicyDetail,
    },
    claims: { newClaimStarted },
    orcaInsurance: { getQuoteValidation },
  } = store;

  const getAlphabeticalSortedPets = useAlphabeticalSortedPets();

  const handleOpenIframe = () => {
    setOpenChangeCoverageFrame(true);
  };

  const loadingInfo = useMemo(() => loading || loadingOtherPolicy
    || loadingDefaultPolicy, [
    loading,
    loadingOtherPolicy,
    loadingDefaultPolicy,
  ]);

  const isGPI = useMemo(() => selectedPolicy
    ?.ProductFamilyId === PRODUCT_FAMILY.GPI, [selectedPolicy]);

  const currentPet = useMemo(
    () => multibrandPolicies.find((pet) => String(petId) === String(pet.PetId)),
    [petId, multibrandPolicies],
  );

  const init = useCallback(() => {
    const petData = getAlphabeticalSortedPets
      ?.find((item) => item.PetId === +petId);
    const policy = policyId
      ? petData?.policies.find((p) => p.Number === policyId)
      : petData?.policies?.[0];
    if (policy?.Number) {
      dispatch(loadPolicyDetails(policy.Number));
      dispatch(loadReimbursementMethod(policy.Number));
      dispatch(loadPlanSummaryDetails(policy.Number));
      dispatch(policyDetailCleaned());
    } else if (policyId && /^\d+$/g.test(policyId)) {
      dispatch(getPolicyDetail({ policyId }));
    } else if (petId) {
      dispatch(getMultibrandDefaultPolicy(petId));
    }
    setSelectedPolicy(policy);
  }, [getAlphabeticalSortedPets, petId, policyId]);

  const onCloseIframe = async () => {
    await sleep(2000);
    setOpenChangeCoverageFrame(false);
    init();
  };

  useEffect(() => {
    if (loading || !allPets.length) {
      return;
    }

    init();
  }, [
    allPets,
    dispatch,
    init,
    loading,
  ]);

  useEffect(() => {
    dispatch(clearPolicyNotifications());
    dispatch(loadFolderStructure());
    dispatch(loadPoliciesOverview());
  }, []);

  const zipCodeMemo = useMemo(() => personalInformation.zipCode,
    [personalInformation]);

  useEffect(() => {
    if (policyDetails && selectedPolicy
      && policyDetails.PolicyNumber === selectedPolicy.Number) {
      const { EffectiveDate } = policyDetails;
      const effectiveDate = EffectiveDate.split('T')[0];

      dispatch(loadWhatWeCoverInfo({
        effectiveDate,
        policyNumber: selectedPolicy.Number,
        stateAbbr: personalInformation.state,
      }));
    }
  }, [
    dispatch,
    selectedPolicy,
    policyDetails,
    zipCodeMemo,
    personalInformation.state,
  ]);

  useEffect(() => {
    const { userInfo: { CustomerId } } = personalInformation;

    if (Object.entries(multiModalData).length === 0) {
      dispatch(loadMultiModalData({ CustomerId }));
    }
  }, [
    dispatch,
    personalInformation.userInfo.CustomerId,
  ]);

  useEffect(() => {
    if (getAlphabeticalSortedPets
      && getAlphabeticalSortedPets.length
      && !petId) {
      dispatch(setSelectedPetId(getAlphabeticalSortedPets[0].PetId));
    }
  }, [dispatch, getAlphabeticalSortedPets, petId]);

  useEffect(() => {
    if (location?.state?.changeCoverageId) {
      dispatch(refreshPolicies());
    }
  }, []);

  useEffect(() => {
    if (!multibrandPolicies.length) {
      dispatch(getMultibrandPolicies());
    }
  }, [multibrandPolicies.length]);

  const onlyFigo = useMemo(() => {
    if (companies.length === 1) {
      const [company] = companies;
      return company.Id === MARKETING_CHANNEL_ID.figo;
    }
    return false;
  }, [companies]);

  const isCostcoAvailable = (webviewData) => webviewData && webviewData.Id
    === MARKETING_CHANNEL_ID.figo && !!webviewData.GetQuoteCostcoUrl;

  useEffect(() => {
    if (getQuoteValidation.requested) {
      const { hasMultipleOrcaPolicies, webviewsData } = getQuoteValidation;

      // multiple policies
      if (hasMultipleOrcaPolicies) {
        setShowMultiplePoliciesError(true);
      } else if (webviewsData.length === 1) {
        // single policy scenario
        const [webviewData] = getQuoteValidation.webviewsData;

        // previous Costco policy
        if (isCostcoAvailable(webviewData)) {
          setShowCostcoMemberQuoteModal(true);
          return;
        }

        // quote by brand
        onGetQuoteByBrand({
          marketingChannelId: webviewData.Id,
          petId: currentPet.PetId,
          quoteUrl: webviewData.GetQuoteUrl,
        });
      } else {
        setShowProviderSelector(true);
      }
    }
  }, [getQuoteValidation]);

  const handleGetAQuote = useCallback(({ petID }) => {
    dispatch(validateBreedMultibrand({ petId: petID }));
  }, [onGetQuoteByBrand, companies]);

  const handleGetQuoteByBrand = () => (providerId) => {
    setShowProviderSelector(false);

    const webviewData = getQuoteValidation.webviewsData
      .find((webview) => webview.Id === providerId) || {};

    if (isCostcoAvailable(webviewData)) {
      setShowCostcoMemberQuoteModal(true);
      return;
    }

    onGetQuoteByBrand({
      marketingChannelId: providerId,
      petId: currentPet.PetId,
      quoteUrl: webviewData.GetQuoteUrl,
    });
  };

  const closeProviderSelector = () => {
    setShowProviderSelector(false);
  };

  const onCloseCostcoMemberModalPress = () => {
    setShowCostcoMemberQuoteModal(false);
  };

  const onContinueWithCostco = () => {
    const webviewData = getQuoteValidation.webviewsData
      .find((webview) => webview.Id === MARKETING_CHANNEL_ID.figo) || {};

    onGetQuoteByBrand({
      marketingChannelId: MARKETING_CHANNEL_ID.figo,
      petId: currentPet.PetId,
      quoteUrl: webviewData.GetQuoteCostcoUrl,
    });

    setShowCostcoMemberQuoteModal(false);
  };

  const onContinueWithStandardQuote = () => {
    const webviewData = getQuoteValidation.webviewsData
      .find((webview) => webview.Id === MARKETING_CHANNEL_ID.figo) || {};

    onGetQuoteByBrand({
      marketingChannelId: MARKETING_CHANNEL_ID.figo,
      petId: currentPet.PetId,
      quoteUrl: webviewData.GetQuoteUrl,
    });

    setShowCostcoMemberQuoteModal(false);
  };

  const policyData = useMemo(() => {
    const pet = multibrandPolicies.find((item) => item.PetId === +petId) || {};
    let policy = defaultPolicy;

    if (policyId) {
      policy = otherPolicyDetail;
    }

    const selectedPetData = pet;
    if (policy?.MarketChannelId === MARKETING_CHANNEL_ID.figo
      || (!policyId && !defaultPolicy)
      || (policyId && !otherPolicyDetail)) {
      const petData = getAlphabeticalSortedPets
        ?.find((item) => item.PetId === +petId);

      const item = (policyId
        ? petData?.policies.find((p) => p.Number === policyId)
        : petData?.policies?.[0]) || {};

      policy = {
        Benefit: item?.Benefit,
        BillingCycle: billingDetails?.PaymentPlan,
        CancellationDate: item?.CancellationDate,
        CoverageSummary: null,
        Deductible: item?.Deductible,
        Extras: item?.PowerUps?.map((powerup) => ({
          Description: powerup.Description,
          Included: powerup.IsIncluded,
          InsuranceModifierId: powerup.InsuranceModifierId,
          Name: powerup.FullName,
          OptionalBenefits: powerup.OptionalBenefits,
        })),
        Id: item?.Number,
        MarketChannelId: MARKETING_CHANNEL_ID.figo,
        NextBillingDate: item?.NextBillingDate,
        PaymentOption: {
          ExpirationMonth: billingDetails?.CCExpMonth,
          ExpirationYear: billingDetails?.CCExpYear,
          FirstNameOnCard: paymentMethodData?.firstName,
          LastFour: billingDetails?.CCCardNumber
            ?? billingDetails?.ACHAccountNumber,
          LastNameOnCard: paymentMethodData?.lastName,
          PaymentMethodName: item?.PaymentType,
        },
        PetCoverage: [],
        PolicyDisplayNumber: item?.Number,
        PolicyEndDate: item?.ExpirationDate,
        PolicySourceId: 0,
        PolicyStartDate: item?.CoverageStartDate,
        Reimbursement: item?.Reimbursement,
        ReimbursementOption: {
          AccountNumberLastFour: reimbursementMethod?.AccountNumber,
          CheckAddress: reimbursementMethod?.Address,
          ReimbursementMethodId: reimbursementMethod
            ?.PreferredReimbursementMethod,
          RoutingNumberLastFour: String(
            reimbursementMethod?.RoutingNumber,
          ).slice(-4),
        },
        SelectedPaymentOptionId: '', // TODO:
        SelectedReimbursementOptionId: '',
        StatusDisplay: Object.values(MULTIBRAND_POLICY_STATUS)[item.Status],
        TotalPremium: item.PayAmount,
      };
    }

    const coverages = policy?.PetCoverages
      ?.find((summary) => summary.Pet.Id === +petId);
    const coverageSummary = coverages?.CoverageSummary;
    const extras = coverages?.Extras || policy?.Extras || [];

    const data = {
      loading: loadingInfo,
      selectedPet: selectedPetData,
      selectedPolicy: {
        ...policy,
        ...coverageSummary,
        Benefit: policy?.Benefit || coverageSummary?.BenefitAmount,
        Deductible: policy?.Deductible || coverageSummary?.Deductible,
        Extras: extras,
        PetCoverages: policy?.PetCoverages
          ?.filter((coverage) => coverage.Pet.Id !== +petId),
      },
    };

    const isOrcaPolicy = policy?.MarketChannelId !== MARKETING_CHANNEL_ID.figo;
    if (isOrcaPolicy) {
      const details = policy?.ReimbursementOption?.Details || {};

      return {
        ...data,
        selectedPolicy: {
          ...data.selectedPolicy,
          ReimbursementOption: {
            ...data.selectedPolicy.ReimbursementOption,
            CheckAddress: `${details.Address}, ${details.City}`,
          },
        },
      };
    }

    return data;
  }, [
    billingDetails,
    defaultPolicy,
    getAlphabeticalSortedPets,
    loadingInfo,
    multibrandPolicies,
    otherPolicyDetail,
    paymentMethodData,
    policyDetails,
    reimbursementMethod,
  ]);

  const getSelectedPolicyDirectory = useCallback((policyNumber) => {
    const directory = folderStructure
      .find((dir) => dir.Id === INBOX_DOCS.policyDocuments);

    if (directory) {
      // TODO: Remove 'Childrens' validation when v2 is complete
      const selectedDirectory = directory
        ?.Childrens.find((dir) => dir.Name === policyNumber)
        || directory?.Folders.find((dir) => dir.Name === policyNumber);

      if (selectedDirectory) {
        return selectedDirectory.Id;
      }
    }

    return null;
  }, [folderStructure]);

  const getPolicyDocumentsFolder = useCallback(() => folderStructure
    .find((dir) => dir.Id === INBOX_DOCS.policyDocuments), [folderStructure]);

  const policyDocumentsURL = useMemo(() => {
    let folderName = policyData?.selectedPolicy?.PolicyFolderDescription;
    let folderId = policyData?.selectedPolicy?.PolicyFolderId;
    if (!folderId) {
      folderName = policyData?.selectedPolicy?.PolicyDisplayNumber;
      folderId = getSelectedPolicyDirectory(folderName);
    }

    if (folderName && folderId) {
      return `${INBOX_DOCS_ROUTE}/${folderName}/${folderId}`;
    }

    const defaultFolder = getPolicyDocumentsFolder();
    if (defaultFolder) {
      return `${INBOX_DOCS_ROUTE}/${defaultFolder.Name}/${defaultFolder.Id}`;
    }

    return INBOX_DOCS_ROUTE;
  }, [policyData, getSelectedPolicyDirectory, getPolicyDocumentsFolder]);

  if (loadingInfo || !currentPet) {
    return (
      <div className="Policy-summary-shimmer-content">
        <FigoLottie
          animationData={loadingSpinnerBlue}
          height={50}
          width={50}
        />
      </div>
    );
  }

  function onMultiModalClose() {
    setMultiModalvisible(false);
  }

  function onClickLearnMore(categoryId) {
    setMultiModalvisible(true);

    if (policyData?.selectedPolicy?.MarketChannelId
      === MARKETING_CHANNEL_ID.figo && categoryId) {
      setMultiModalCategoryId(categoryId);
    }
  }

  function closePrimaryVetModal() {
    setShowPrimaryVetModal(false);
  }

  const onCloseErrorModal = () => {
    setShowMultiplePoliciesError(false);
  };

  const handleAddPetClick = () => {
    history.push(ADD_ORCA_PET, {
      selectedPolicy: policyData.selectedPolicy,
    });
  };

  function renderAddAPetButton() {
    const { selectedPolicy: policy } = policyData;
    if (policy?.MarketChannelId === MARKETING_CHANNEL_ID.figo
      || policy?.StatusDisplay !== MULTIBRAND_POLICY_STATUS.active) {
      return null;
    }
    return (
      <div className="Policy-summary-add-pet">
        <ActionButton
          isSecondaryButton
          leftIcon={addIconOnyx}
          leftIconClassName="Policy-summary-add-pet-icon"
          noMargin
          onClick={handleAddPetClick}
          title={t('addPet')}
        />
      </div>
    );
  }

  function renderFileAClaimButton() {
    if (policyData?.selectedPet?.Policies?.length === 0
      || isGPI || !policyData?.selectedPolicy?.Id) {
      return null;
    }

    return (
      <FileClaimButton
        className="Policy-summary-file-claim"
        icon={addIcon}
      />
    );
  }

  function renderPolicyInfo() {
    if (policyData?.StatusDisplay === MULTIBRAND_POLICY_STATUS.cancelled) {
      return (
        <CoverageOverview
          isCancelled
          loading={loadingInfo}
          loadingOverview={loadingOverview}
          marketingChanneld={policyData.selectedPolicy?.MarketChannelId}
          policies={store.policies}
          selectedPolicy={policyData?.selectedPolicy}
        />
      );
    }

    return (
      <>
        <CoverageOverview
          loading={loadingInfo}
          loadingOverview={loadingOverview}
          marketingChanneld={policyData.selectedPolicy?.MarketChannelId}
          onOpenIframe={handleOpenIframe}
          policies={store.policies}
          selectedPolicy={policyData?.selectedPolicy}
        />

        <Powerups
          company={policyData?.selectedPolicy?.MarketChannelId}
          items={policyData?.selectedPolicy?.Extras}
          onClickLearnMore={onClickLearnMore}
        />

        {policyData?.selectedPolicy?.MarketChannelId
          !== MARKETING_CHANNEL_ID.ppiOnePack
          && (
            <BillingAndPayments
              company={policyData.selectedPolicy?.MarketChannelId}
              selectedPolicy={policyData?.selectedPolicy}
            />
          )}

        {policyData?.selectedPolicy?.MarketChannelId
          !== MARKETING_CHANNEL_ID.ppiOnePack
          ? (
            <Reimbursement
              selectedPolicy={policyData?.selectedPolicy}
            />
          ) : null}

      </>
    );
  }

  function renderContactUsModal() {
    const { policyProvider, petValidBreed } = getQuoteValidation;
    let extraText = null;
    let companyStep = CONTACT_US_STEPS.PPI_ONE_PACK;
    const show = showMultiplePoliciesError
      || showPPIGroupModal
      || showInvalidBreedModal;
    let close = () => { };

    if (showMultiplePoliciesError) {
      // More than one active policies error
      extraText = <p>{t('multiplePoliciesError')}</p>;
      close = onCloseErrorModal;
      companyStep = CONTACT_US_STEPS[policyProvider] || CONTACT_US_STEPS.HOME;
    } else if (showPPIGroupModal) {
      // add coverage to PPIG
      extraText = (
        <>
          <p>{t('addCoverageDesc')}</p>
          <p>{t('addCoverageDesc2')}</p>
        </>
      );
      close = onClosePPIGroupModal;
      companyStep = CONTACT_US_STEPS.PPI_ONE_PACK;
    } else if (showInvalidBreedModal) {
      // invalid breed for figo quote
      extraText = (<p>{petValidBreed}</p>);
      close = onCloseInvalidBreedModal;
      companyStep = CONTACT_US_STEPS.FIGO;
    }
    return (
      <ContactUsModal
        companyStep={companyStep}
        custom
        customTitle={t('addCoverage')}
        extraText={extraText}
        hasHeader
        onClose={close}
        show={show}
      />
    );
  }

  if (openChangeCoverageFrame) {
    return (
      <ChangeCoverageFrame
        onClose={onCloseIframe}
        petId={petId}
        policy={policyData?.selectedPolicy}
      />
    );
  }

  return (
    <div
      className={'Policy-summary-main-container'
        + ` Colors-${policyData.selectedPolicy?.MarketChannelId}`}
    >
      <PetCloudBreadcrumbs
        activeRoute={currentPet.PetName}
        rootRoute={t('title')}
        rootRouteLink={INSURANCE}
      />

      <div className="Policy-summary-title-page-container">
        <h1>{t('title')}</h1>

        <div className="Policy-summary-header-actions">
          {renderAddAPetButton()}

          {renderFileAClaimButton()}
        </div>
      </div>

      <PolicyNotifications
        petId={petId}
        policy={selectedPolicy}
      />

      <PolicyModals
        changeCoverageValidation={changeCoverageValidation}
        company={policyData?.selectedPolicy?.MarketChannelId}
        loadingSeeMoreInfo={loadingSeeMoreInfo}
        multiModalCategoryId={multiModalCategoryId}
        multiModalData={multiModalData}
        multiModalVisible={multiModalVisible}
        myVetsInfo={store.myVets}
        newClaimStarted={newClaimStarted}
        onClosePrimaryVetModal={closePrimaryVetModal}
        onMultiModalClose={onMultiModalClose}
        petID={petId}
        policyNumber={policyData.selectedPolicy.PolicyDisplayNumber}
        selectedPolicy={selectedPolicy}
        showPrimaryVetModal={showPrimaryVetModal}
        t={t}
      />

      {!!policyData?.selectedPolicy?.Id && !policyData.loading ? (
        <>
          <PolicyHeader company={policyData.selectedPolicy?.MarketChannelId} />

          <div className="Policy-summary-info-container">
            <div className="Policy-summary-title">
              <h2>{t('myPet')}</h2>

              {policyDocumentsURL && (
                <ViewMoreButton
                  isLink
                  text={t('viewPolicyDocuments')}
                  to={policyDocumentsURL}
                />
              )}
            </div>

            <CurrentPetPolicy
              currentPet={policyData.selectedPet}
              loading={policyData.loading}
            />

            {renderPolicyInfo()}
          </div>
        </>
      ) : (
        <>
          <NoPolicyPets
            currentPet={currentPet}
            isYoungPetModalVisible={isYoungPetModalVisible}
            onGetQuote={handleGetAQuote}
            onlyFigo={onlyFigo}
          />

          {!onlyFigo && (
            <ProvidersSelector
              onClose={closeProviderSelector}
              onProviderSelected={handleGetQuoteByBrand(currentPet)}
              show={showProviderSelector}
            />
          )}

          <CostcoMemberQuoteModal
            onClosePress={onCloseCostcoMemberModalPress}
            onContinueWithCostco={onContinueWithCostco}
            onContinueWithStandardQuote={onContinueWithStandardQuote}
            visible={showCostcoMemberQuoteModal}
          />

          {renderContactUsModal()}
        </>
      )}
    </div>
  );
};

export { PolicySummary };
